import { createSlice } from "@reduxjs/toolkit";



const initialState =[]

export const availableExperimentSlice = createSlice({
    name: 'available_experiments',
    initialState,
    reducers:{
        addAvaiableExperiment(state, action){
            state.push(action.payload)
        }
    }
})

export const selectAllAvailableExperiments = (state) => state.avalable_experiments;
export const { addAvailableExperiment } = availableExperimentSlice.actions;
export default availableExperimentSlice.reducer;

import {Route, Routes} from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import LoadingData from "./data/loadData"
import CompleteRegistration from "./pages/CompleteRegistration";
import './App.css';

function App() {

    return (
    <Routes>
        <Route path="/" element={<Login/> }/>
        <Route path="/loading" element={<LoadingData/> } />
        <Route path="/complete_registration" element={<CompleteRegistration/>}/>
        <Route path="/dashboard" element={<Home/> }/>
    </Routes>
    );
}

export default App;
import { Navigate, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useSelector } from 'react-redux'
import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import Stack  from "react-bootstrap/Stack";
import Image from "react-bootstrap/Image";
import Col from 'react-bootstrap/Col'
import { Nav } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
import { addSubject, selectAllSubject } from "../redux/subjectSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";



function TopMenu(){

    let subject = useSelector(selectAllSubject) 
    const navigate = useNavigate();
    const auth = useAuth()

    const logOut = () => {
        sessionStorage.setItem('isAuthenticated', 'false');
        localStorage.clear();
        navigate('/');
      };


    return(
       <Stack direction="horizontal" gap={3} className={'d-flex justify-content-between p-2 mb-3 bg-secundario fixed-top'}>
        <Col className={'d-flex'}>
            <div className={'w-75  bg-primario logo-bg-transform'}>
                <Image src={"/logo_blueBG.svg"} className={'logo-position'}></Image>
            </div>
        </Col>
        <Col className={'d-flex justify-content-end align-items-center'}>
            <Nav>
                { subject ? 
                    <NavDropdown 
                    className={'noto-sans super-subtitles-size text-white mb-0'}
                    title={<FontAwesomeIcon icon={faUser}/>}
                    >
                        <NavDropdown.Item >Administrar Cuenta</NavDropdown.Item>
                        <NavDropdown.Item onClick={()=> logOut()}>Logout </NavDropdown.Item> 
                </NavDropdown> : <Nav.Link>
                    <p className={'noto-sans super-subtitles-size text-white mb-0'}>Nosotros</p>
                </Nav.Link>  }
            </Nav>
        </Col>
       </Stack>
        
    );
}

export default TopMenu;
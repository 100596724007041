import { createSlice } from "@reduxjs/toolkit";



const initialState =[]

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers:{
        addMessage(state, action){
            state.push(action.payload)
        }
    }
})

export const selectAllMessage = (state) => state.message;
export const { addMessage } = messageSlice.actions;
export default messageSlice.reducer;
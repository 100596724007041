import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import { addExperiment, selectAllExperiments } from "../redux/experimentsSlice";
import { addSubject, selectAllSubject } from "../redux/subjectSlice";
import Spinner from 'react-bootstrap/Spinner';
import { addMessage } from "../redux/messageSlice";

export function LoadingData(){

    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate();

    const { auth } = useAuth();
    console.log('AUTH', auth);

    let [experimentsLoad, setExperimentLoad]= useState();
    let [subjectLoad, setsubjectLoad]= useState();
    let [messageLoad, setMessagetLoad]= useState();
    
    let loading = (experimentsLoad || subjectLoad || messageLoad ) === undefined ? true : false;

    useEffect(() => {
        const controller = new AbortController(); // <-- create controller
        getExperiments({ controller }); // <-- pass controller
        getSubject({ controller });
        getMessage({ controller });
        return () => controller.abort(); // <-- return cleanup function
      }, []);

    
    const getExperiments = async (controller) => {
        try {
            const response = await axiosPrivate.get('api/experiments/available/', {
                signal: controller.signal,
                headers:{
                    'Authorization': `Bearer ${auth.accessToken}`
                },
            });
            setExperimentLoad(true)
            dispatch(addExperiment(response.data))
        } catch (err) {
            console.error(err);
        }
    }

    const getSubject = async (controller) => {
        try {
            const response = await axiosPrivate.get('api/subjects/', {
                signal: controller.signal,
                headers:{
                    'Authorization': `Bearer ${auth.accessToken}`
                },
            });
            setsubjectLoad(true)
            dispatch(addSubject(response.data))
        } catch (err) {
            console.error(err);
        }
    }

    const getMessage = async (controller) => {
        try {
            const response = await axiosPrivate.get('api/greeting', {
                signal: controller.signal,
                headers:{
                    'Authorization': `Bearer ${auth.accessToken}`
                },
            });
            setMessagetLoad(true)
            dispatch(addMessage(response.data))
        } catch (err) {
            console.error(err);
        }
    }
    
    if (loading){
        <Spinner/>
    } else {
        navigate('/dashboard')
    }
}

export default LoadingData;


import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from "../context/AuthProvider";
import axios from '../api/axios';
import LoadingData from '../data/loadData';

import Card from 'react-bootstrap/Card';
import Button  from "react-bootstrap/Button";
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';


const SUBJECT_URL = '/api/subjects/';

export function CompleteSubjectInfo(){

    const navigate = useNavigate();

    const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();

    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [educationLevel, setEducationLevel] = useState('');
    const [email, setEmail] = useState('')
    const [nationality, setNationality] = useState('');
    const [birthRegion, setBirthRegion] = useState('');
    const [residenceCountry, setResidenceCountry] = useState('');
    const [residenceRegion, setResidenceRegion] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [allowNotifications, setAllowNotifications] = useState();




    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {  

            const response = await axios.post(SUBJECT_URL,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Headers': '*' 
                    }, 
                    data:{
                        'gender': gender,
                        'level_of_education': educationLevel,
                        'nationality': nationality,
                        'birthplace_region': birthRegion,
                        'residence_country': residenceCountry,
                        'residence_region': residenceRegion,
                        'birthdate': birthDate,
                        'allow_notifications': allowNotifications}
                }   
            );
            console.log('RESPONSE', response?.data);
            setSuccess(true);
            LoadingData()
            navigate('/dashboard')
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else {
                setErrMsg('Algo ha fallado');
            }
        }
    }




    return(
        <Stack gap={2}>
            <p className={'noto-sans subtitles-size text-secundario fw-bold text-center mt-3'}>Completa tus Datos</p>
            <p> Hola! para encontrar los experimentos disponibles para usted, necesitamos que especifique algunos aspectos personales</p>
            <p ref={errRef} className={errMsg ? "errmsg" : "d-none"} aria-live="assertive">{errMsg}</p>
            <Container>
                <Row>
                    <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3 w-75 m-auto">
                        <Row>
                            <Col>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Genero"
                                    id='gender'
                                    ref={userRef}
                                    onChange={(e) => setGender(e.target.value)}
                                    value={gender}
                                    required
                                    className={'mb-3'} 
                                />
                                <Form.Control 
                                    type="text" 
                                    placeholder="Nivel de educacion"
                                    id='educationLevel'
                                    ref={userRef}
                                    onChange={(e) => setEducationLevel(e.target.value)}
                                    value={educationLevel}
                                    required 
                                    className={'mb-3'} 
                                />
                                <Form.Control 
                                    type="text" 
                                    placeholder="Nacionalidad"
                                    id='nationality'
                                    ref={userRef}
                                    onChange={(e) => setNationality(e.target.value)}
                                    value={nationality}
                                    required 
                                    className={'mb-3'} 
                                />
                                <Form.Control 
                                    type="text" 
                                    placeholder="Region de Nacimiento"
                                    id='birthRegion'
                                    ref={userRef}
                                    onChange={(e) => setBirthRegion(e.target.value)}
                                    value={birthRegion}
                                    required 
                                    className={'mb-3'} 
                                />
                            </Col>
                            <Col>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Pais de residencia"
                                    id='residenceCountry'
                                    ref={userRef}
                                    onChange={(e) => setResidenceCountry(e.target.value)}
                                    value={residenceCountry}
                                    required 
                                    className={'mb-3'} 
                                />
                                <Form.Control 
                                    type="text" 
                                    placeholder="Region de residencia"
                                    id='recidenceRegion'
                                    ref={userRef}
                                    onChange={(e) => setResidenceRegion(e.target.value)}
                                    value={residenceRegion}
                                    required 
                                    className={'mb-3'} 
                                />
                                <Form.Control 
                                    type="date" 
                                    placeholder="Fecha de Nacimiento"
                                    id='birthDate'
                                    ref={userRef}
                                    onChange={(e) => setBirthDate(e.target.value)}
                                    value={birthDate}
                                    required 
                                    className={'mb-3'} 
                                />
                                <Form.Check
                                    inline
                                    label="Recibir Notificaciones"
                                    name="allow_notifications"
                                    type={'checkbox'}
                                    id={'allowNotifications'}
                                    className={'mb-3'} 
                                />
                            </Col>
                        </Row>   
                    </Form.Group>
                    <Button className={'m-auto w-75'} variant="primary" type='submit'> Completar Registro </Button>
                    </Form>
                </Row>
            </Container>
        </Stack>
);
}

export default CompleteSubjectInfo;
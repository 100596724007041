import { createSlice } from "@reduxjs/toolkit";



const initialState =[]

export const experimentSlice = createSlice({
    name: 'experiments',
    initialState,
    reducers:{
        addExperiment(state, action){
            state.push(action.payload)
        }
    }
})

export const selectAllExperiments = (state) => state.experiments;
export const { addExperiment } = experimentSlice.actions;
export default experimentSlice.reducer;
import {useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import ActivityCard from "../commons/ActivityCard";
import { selectAllExperiments } from '../redux/experimentsSlice';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { Stack } from 'react-bootstrap';
import { Button } from 'react-bootstrap';


function ExperimentList(){
    
    let exp = useSelector(selectAllExperiments)[0]
    
    let [onlineExp, setOnlineExp]= useState([]);
    let [presenciaExp, setPresencialExp]= useState([]);


    let [filterSelection, setFilterSelection]= useState(exp)
    let [modeSelected, setModeSelected] = useState()

    let [filterHardwareSelection, setFilterHardwareSelection]= useState(exp)
    let [hardwareSelected, setHardwareSelected] = useState()


    useEffect(()=>{
        if (exp === undefined){
            setOnlineExp()
            setPresencialExp()
        } else {
        setOnlineExp( exp.filter( e => e.is_scheduled === false))
        setPresencialExp( exp.filter( e => e.is_scheduled === true))}
    },[exp])

    
    let filters =[ 
        { name : 'Todos', value: 'todos', btn: 'btn-primary', outln: 'outline-primario'},
        { name : 'Online', value: 'online', btn: 'btn-red', outln: 'outline-red'},
        { name : 'Presencial', value: 'presencial', btn: 'btn-green', outln: 'outline-green'}
        
    ]

    let hardware =[ 
        { name : 'Ninguno', value: 'ninguno'},
        { name : 'Pc', value: 'pc'},
        { name : 'Auriculares', value: 'auricular'},
        { name : 'Celular', value: 'cel'}
    ]

    const handleFilter = (value) => {
        if (value === 'online' ) {
            setFilterSelection(onlineExp)
            setModeSelected(value)
        } else if ( value === 'presencial') {
            setFilterSelection(presenciaExp)
            setModeSelected(value)
        } else {
            setFilterSelection(exp)
            setModeSelected('todos')
        }
    };


    const handleHardwareFilter = (value) => {
        
    };
    
    if (exp === undefined){
        return null
    }

    return(
        <Row className={'bg-neutral mt-3 px-4'}> 
            <p className={'noto-sans super-subtitles-size text-secundario fw-bolder p-3'}> Experimentos </p>
            <Row className={'mb-3 d-flex justify-content-start'}>
                <Col>
                <p className={'texts-size d-inline px-3'}>Filtros:</p>
                    <ButtonGroup className={'mr-3'}>
                        {filters.map((filter, idx) => (
                            <ToggleButton
                            key={idx}
                            id={`filter-${idx}`}
                            type="radio"
                            variant={modeSelected === filter.value ? 'primary' : 'outline-secondary'}
                            className={modeSelected === filter.value ? filter.btn : 'outline-secondary'}
                            name="mode_filter"
                            value={filter.value}
                            checked={filterSelection === filter.value}
                            onChange={(e) => handleFilter(e.currentTarget.value)}
                            >
                            {filter.name}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                    <ButtonGroup  className={'mx-3'}>
                        {hardware.map((hw, idx) => (
                            <ToggleButton
                            key={idx}
                            id={`hardware-${idx}`}
                            type="radio"
                            variant={hardwareSelected === hw.value ? 'primary' : 'outline-secondary'}
                            name='hardware_filter'
                            value={hw.value}
                            checked={filterHardwareSelection === hw.value}
                            onChange={(e) => handleFilter(e.currentTarget.value)}
                            >
                            {hw.name}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                </Col>
                </Row>
                {
                filterSelection.map(a => 
                <Col md={3} className={'d-flex align-items-stretch'}>
                    <ActivityCard 
                        type={a.is_scheduled ? 'Presencial' : 'Online'} 
                        id={a.id}                        
                        image= {a.image}
                        title= {a.name}
                        estimated_time= {'x minutos'}
                        text= {a.description}
                        is_scheduled= {a.is_scheduled}
                        date_created= {a.creation_datetime}
                        owner= {a.owner}
                        url={a.url}
                    />
                    </Col> 
                )}
        </Row>
    )
} 

export default ExperimentList;

import '../App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image  from 'react-bootstrap/Image';
import TopMenu from '../commons/TopMenu';
import Footer from '../commons/footer';
import {LoginForm, RegisterForm } from '../commons/LoginForm';

function Login() {

  return (
    <Container className={'m-0'}fluid >
      <Row className={'mb-3 pb-3 pl-3'} >
        <TopMenu/>
        <div className={'m-0 p-0 w-75'}>
          <Image src={'/landing_image.svg'} className={'bg-float'} ></Image>
        </div>
      </Row >
        <Col md={8} className={' pt-3 content-wrapper'}>
          <p className={'nunito-sans super-titles-size p-3 m-3'}>¡Bienvenidx a nuestra plataforma de experimentos online!</p>
          <p className={'crimson super-subtitles-size p-3 mx-3 w-75'}>Breve descripcion de los objetivos  o para que sirven los datos recolectadosen relacion a la ciencia.</p>
          <p className={'noto-sans subtitles-size text-secundario fw-bold p-3 mx-3 w-75'}>Ayudanos a recolectar datos para fines científicos :D</p>
        </Col>
      <Row>
      <Col className={'bg-light w-100 p-3'}>
        </Col>
      </Row>
      <Row className={'bg-neutral'}>
        <Col md={4} className={'p-4'}>
          <LoginForm/>
        </Col>
        <Col md={4} className={'p-4'}>
          <RegisterForm/>
        </Col>
      </Row>
      <div className={'bg-img bg-float'}> </div>
      <Footer/>
    </Container>
  );
}

export default Login;

import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import experimentsReducer from  './experimentsSlice'
import subjectReducer from "./subjectSlice";
import messageReducer from "./messageSlice";
import availableExperimentsReducer from "./availableExperimentsSlice";
import { version } from "react";


const rootReducer = combineReducers({
    experiments: experimentsReducer,
    subject: subjectReducer,
    message: messageReducer,
    avalable_experiments: availableExperimentsReducer
}
);

const persistConfig = {
    key: 'root',
    storage,
    version: 1
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    getDefaultMiddleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serrializableCheck: false,})
})


export const persistor =  persistStore(store)

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CardBody, CardHeader, ModalTitle } from "react-bootstrap";
import ScheduleExperiment from "../components/scheduleExperiment";
import Card from "react-bootstrap/Card"
import Image from "react-bootstrap/Image";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

import {faHourglass} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ActivityCard({type, image, title, estimated_time, text, is_scheduled , date_created, owner, url, id}) {
    
    const BASE_URL = 'https://npdev.liaa.dc.uba.ar/'
    const navigate = useNavigate();
    
    let [showModal, setShowModal] = useState(false)

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const expDetailModal = 
    <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header className="gradient-bg align-items-baseline" closeButton>
            <ModalTitle></ModalTitle>
            <Container>
                <Row className={'m-0 p-0'}> 
                    <Col sm={6}>
                        <p className={'pt-sans fw-bolder super-subtitles-size'}>{title}</p>
                    </Col>
                    <Col>
                        <Image src={ BASE_URL + image} className={'activity-card-image rounded-top'}/>
                    </Col>
                </Row>
            </Container>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Row className="w-75 m-auto">
                    <Col>
                        <Table>
                            <thead className={'bg-primario text-white texts-size w-100'}>
                                <tr >
                                    <th>ESPECIFICACIONES</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Autor</td>
                                    <td>{owner}</td>
                                </tr>
                                <tr>
                                    <td>Descripcion</td>
                                    <td>{text}</td>
                                </tr>
                                <tr>
                                    <td>Tiempo Estimado</td>
                                    <td>{estimated_time}</td>
                                </tr>
                                <tr>
                                    <td>Requisitos</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container> 
            </Modal.Body>

    </Modal>
    console.log('TITLE', title)
    return(
        <>
        <Card className={'section-card shadow-sm m-2 no-border p-2 m-0 no-border no-radius-border'}>
            <CardHeader className={'d-flex align-content-center gradient-bg no-border no-radius-border p-0 '}>
                <small className={'activity-type-flag text-center text-white fw-bold' + (type === 'Online' ? ' bg-red' : ' bg-green')}> {type}</small>    
                <Image src={ BASE_URL + image} className={'activity-card-image my-4 py-3 rounded-top'}/>
            </CardHeader>
            <CardBody className={'d-flex flex-column justify-content-center'}>
                <h4 className={'pt-sans fw-bolder'}>{title}</h4>
                <p><FontAwesomeIcon icon={faHourglass} color="#2DC2E5" size="sm"/><small className={'text-muted px-2 mr-3'}>Tiempo estimado: {estimated_time}</small></p>
                <p className={'text-truncate'}>{text} <br>
                    </br> <a onClick={handleShow} className={'mt-0 pt-0 no-decoration text-secundario cursor'}>Ver mas</a> 
                </p>
            </CardBody>
            <Card.Footer className={'no-border bg-white d-flex flex-column justify-content-center'}>
                { is_scheduled ?
                 <ScheduleExperiment btn_text= {'Agendar Cita'} exp_id={id}/> :
                 <Button variant={'secondary'} className={'w-100 m-auto'}>{'Comenzar'}</Button>
                }
                <small className={'text-muted text-center pt-3 mb-0 fw-bold '}>{date_created}</small>
            </Card.Footer>
        </Card>
        {expDetailModal}
        </>
    );
}

export default ActivityCard;
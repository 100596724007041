import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import axios from '../api/axios';



import { Container, ModalBody, ModalHeader, Stack } from 'react-bootstrap';
import Modal  from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

import { DayPicker, DateAfter, DateBefore  } from "react-day-picker";
import "react-day-picker/style.css";




function ScheduleExperiment ({btn_text, exp_id}) {

    const axiosPrivate = useAxiosPrivate()
    const controller = new AbortController();
    const { auth } = useAuth();

    let id = exp_id
    var moment = require('moment'); 
    var today = moment()
    let initialDateFormated = today.format('YYYY-MM-DD')

    let progressBarTitles = [
        'Paso 1: Eliga fecha del encuentro',
        'Paso 2 : Eliga horario disponible',
        'Paso 3 : Confirmar seleccion'
    ]
    
    let [showScheduleModal, setShowScheduleModal] = useState(false)
    let [now, setNow] = useState(0)
    let [progressBarSteps, setProgressBarTexts] = useState(progressBarTitles[0])
    let [hasDate, sethasDate] = useState(false)
    let [hasTime, setHasTime] = useState(false)

    let [datesunabled, setDatesUnabled]= useState()
    let [startDate, setStartDate]= useState(undefined)
    let [endDate, setEndDate]= useState(undefined)
    let [disabledWeekend, setDisabledWeekend] = useState(undefined)

    let [selectedDate, setSelectedDate] = useState(today)
    let [formatedDateSelected, setFormateddDateSelected] = useState(initialDateFormated)

    let [hoursAvailable, setHoursAvailable] = useState()
    let [hourSelected, setSelectedHour] = useState()

    const getAvailableDates= async (exp_id, controller) => {
        try {
            const response = await axiosPrivate.get(`/api/experiments/${exp_id}/booking_dates/`, {
                signal: controller.signal,
                headers:{
                     'Authorization': `Bearer ${auth.accessToken}`
                },
            });
            console.log('hours-response', response.data)
           setStartDate(new Date ( response.data.start_date))
           setEndDate(new Date(response.data.end_date))
           setDisabledWeekend(response.data.disabled_weekend)
           setDatesUnabled(response.data.disabled_dates)
           controller.abort()
        } catch (err) {
            console.error(err);
        }
    }

    const getAvailableHours= async (exp_id, formatedDateSelected, controller) => {
        try {
            const response = await axiosPrivate.get(`/api/experiments/${exp_id}/avaliable_schedules/${formatedDateSelected}/`, {
                signal: controller.signal,
                headers:{
                     'Authorization': `Bearer ${auth.accessToken}`
                },
            });
            console.log('hours-response', response.data)
           setHoursAvailable(response.data)
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        getAvailableDates(id, controller)
        return () => controller.abort();
    }, [])

   
    
    useEffect(() => {
        const controller = new AbortController();
        getAvailableHours(id, formatedDateSelected, controller)
        return () => controller.abort();
    }, [])


    const clearForm = () => {
        setNow(0);
        setHasTime(false);
        sethasDate(false);
        setProgressBarTexts(progressBarTitles[0])
    }

    const handleClose = () => {
        setShowScheduleModal(false);
        clearForm()
    }
    const handleShow = () => {
        setShowScheduleModal(true);
        getAvailableDates(id, controller);
    }; 
    
    const handleSelectedDate = (e) => {
       let year = e.getUTCFullYear()
       let month = e.getUTCMonth()
       let day = e.getUTCDate()
       let formatdDate = year + '-' + month + '-' + day 
       console.log('FD', formatdDate);
       setSelectedDate(e)
       setFormateddDateSelected(formatdDate)
    }


    const handleSelectedHour= (htime) => {
       let hour = htime
       console.log('HOURe' , hour)
       setSelectedHour(hour)
     }

    const handleNextStep = () => {
        if (!hasDate) {
            getAvailableHours(exp_id, formatedDateSelected, controller)
            sethasDate(true)
            setNow(25)
            setProgressBarTexts(progressBarTitles[1])
        } else if (hasDate & !hasTime){
            setHasTime(true)
            setNow(25)
            setProgressBarTexts(progressBarTitles[2])
        } else if (hasDate & hasTime){
            setNow(25)
        }
    }

    const handleConfirmDate = async (e) => {
        e.preventDefault();
        try {  
            const response = await axios.post(`/api/experiments/${exp_id}/create_booking/`,
               { 
                    experiment: exp_id,
                    date: formatedDateSelected,
                    time: hourSelected
                },
                {
                    headers:{
                        'Authorization': `Bearer ${auth.accessToken}`
                   },
                    withCredentials: false
                }
            );
            console.log('RES', response);
            handleClose()            
            setEndDate(undefined)
            setStartDate(undefined)
            setDisabledWeekend(undefined)
            setDatesUnabled(undefined)
            clearForm()
        } catch (err) {
            console.log('ERR', err)}
    }

    const shceduleModal = <Modal show={showScheduleModal}>
        <ModalHeader> <p className='noto-sans super-subtitles-size text-secundario fw-bolder'>Agendar Cita</p> </ModalHeader>
        <ModalBody className='m-auto'>
            <Container>
            <Row className='mb-4'>
               <p className='pt-sans fw-bolder'>{progressBarSteps}</p>
            </Row>
            <Row className='mb-4'>
                <ProgressBar now={now} label={`${now}%`} visuallyHidden />
            </Row>
            <Row className='mb-4'>
            {!hasDate &&
                <DayPicker
                    mode="single"
                    selected={selectedDate}
                    onSelect={handleSelectedDate}
                    disabled= {[
                        {before: startDate, after: endDate},
                        {dayOfWeek: disabledWeekend ? [0, 6] : undefined}
                ]}   
                /> 
            }
            {hasDate & !hasTime ?
                <Stack direction="horizontal" gap={3} className='flex-wrap my-4 m-auto'>
                    {hoursAvailable.map( (h, index) => 
                        <Button 
                            key={index}
                            variant={   hourSelected === h.time ? "primary" : "outline-primary"}
                            onClick={() => handleSelectedHour(h.time)}> {h.time} </Button>
                    )} 
                </Stack> : null
            }
            { hasTime & hasDate ?
              <p>  Desea Confirmar cita el {formatedDateSelected} a las {hourSelected}</p> : null
            }
            </Row>
            <Row className='d-flex justify-content-around'>
                <Col className='text-center'>                
                    <Button className={'btn-red'} onClick={handleClose}>Cancelar</Button>
                </Col>
                <Col className='text-center'>
                { hasDate && hasTime ? <Button onClick={handleConfirmDate}>Agendar Cita</Button> : <Button onClick={handleNextStep}>Continuar</Button> }  
                </Col>
            </Row>
            </Container>
        </ModalBody>
    </Modal>



  return (
    <>
     <Button variant={'secondary'} className={'w-100 m-auto'} onClick={handleShow}>{btn_text}</Button>
     {shceduleModal}
    </>
  )
}

export default ScheduleExperiment;
import { useSelector } from 'react-redux'
import { selectAllMessage } from '../redux/messageSlice'; 


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



function Greeting(){
    
    let msg = useSelector(selectAllMessage)[0]
    
    console.log('MSG', msg)
    if (msg === undefined){
        return null
    }
    
    return (
        <Container>
            <Row>
                <Col>
                <div dangerouslySetInnerHTML={{ __html: msg.content }}></div>
                </Col>
            </Row>
        </Container>
    );
} 

export default Greeting;
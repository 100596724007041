import axios from 'axios';

const BASE_URL = 'https://npdev.liaa.dc.uba.ar/dj/'

export default axios.create({
    baseURL:  BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});


axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

